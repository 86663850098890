import {Popup} from "devextreme-react/popup";

export function SavePopup(props) {
    return (
        <Popup
            key={'save_dialog'}
            width={500}
            height={120}
            showTitle={false}
            visible={props.visible}
            onHiding={props.onHiding}
            toolbarItems={[{
                toolbar: 'bottom',
                location: 'center',
                widget: 'dxButton',
                options: {
                    text: 'Save changes',
                    stylingMode: 'outlined',
                    onClick: props.onSaveClick,
                },
            }, {
                toolbar: 'bottom',
                location: 'center',
                widget: 'dxButton',
                options: {
                    text: 'Discard changes',
                    onClick: props.onDiscardClick,
                },
            }, {
                toolbar: 'bottom',
                location: 'center',
                widget: 'dxButton',
                options: {
                    text: 'Cancel',
                    onClick: props.onCancelClick,
                },
            }]}
        >
            <div>
                There are unsaved changes to the product plan.
            </div>
        </Popup>
    )
}
