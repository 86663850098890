import {forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {DataGrid, Editing, Scrolling} from "devextreme-react/data-grid";
import {useDispatch, useSelector} from "react-redux";

import {removeEditButtonsFromToolbar} from "../../../components/utils";
import useSalesPlanEntity from "../../../api/useSalesPlanEntity";
import {updateEditState} from "../editMenuSlice";


const STRATEGY = 'sales_history';

function getColumns(monthCols) {
    return [{
        key: 'metric',
        dataField: 'metric',
        caption: 'Lever',
        width: 220,
        fixed: true,
        allowEditing: false,
        allowHeaderFiltering: true,
        allowSorting: true,
        cssClass: 'metric',
    }, ...monthCols?.map(col => ({
        key: col,
        dataField: col,
        dataType: 'number',
        format: '#0.#%',
        editorOptions: {
            format: '#0.#%',
            step: 0.1,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
    }))];
}

export const SalesHistoryForecast = forwardRef((props, ref) => {
    const gridRef = useRef();
    const dispatch = useDispatch();

    const salesEntityId = useSelector(state => state.editMenu.salesEntityId);

    const salesEntity = useCallback(useSalesPlanEntity, [salesEntityId])(salesEntityId).data;
    const {months, startMonth, strategies: {[STRATEGY]: strategy}} = salesEntity;

    const columns = useMemo(() => getColumns(months.filter(m => m >= startMonth)),[months, startMonth]);

    useImperativeHandle(ref, () => ({
        cancelEdit: () => gridRef.current.instance?.cancelEditData(),
        saveEditData: () => gridRef.current.instance?.saveEditData(),
    }));

    const handleOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateEditState({
                strategyType: STRATEGY,
                editData: e.value,
            }));
        }
    };

    return (
        <DataGrid
            ref={e => {
                gridRef.current = e;
                ref(e);
            }}
            keyExpr={'metric'}
            align={'center'}
            height={'100%'}
            columns={columns}
            showBorders={true}
            loadPanel={{enabled: false}}
            dataSource={strategy.data}
            onToolbarPreparing={removeEditButtonsFromToolbar}
            onOptionChanged={handleOptionChanged}
        >
            <Editing
                allowUpdating={true}
                mode={'batch'}
            />
            <Scrolling
                mode={'standard'}
                showScrollbar='always'
                columnRenderingMode='standard'
            />
        </DataGrid>
    )
});
