import {useRef, forwardRef, useImperativeHandle} from "react";
import {Toolbar, Item as ToolbarItem} from "devextreme-react/toolbar";
import {Button} from "devextreme-react/button";
import {MultiView, Item as MultiViewItem} from "devextreme-react/multi-view";
import {Box, Item as BoxItem} from "devextreme-react/box";
import {LoadPanel} from "devextreme-react/load-panel";
import {useDispatch, useSelector} from "react-redux";

import {SalesHistoryForecast} from "./StrategyEditors/SalesHistoryForecast";
import {ManualForecast} from "./StrategyEditors/ManualForecast";
import {ComparativeForecast} from "./StrategyEditors/ComparativeForecast";
import {ReplacementPartForecast} from "./StrategyEditors/ReplacementPartForecast";
import {useSalesEntityId} from "../../api/useAPI";
import {resetEditState} from "./editMenuSlice";
import {setActiveStrategy, resetActiveStrategy} from "./editMenuSlice";
import {useMutateSalesPlanEntity} from "../../api/useSalesPlanEntity";

import '../../css/Detail.css'
import {TotalSalesForecast} from "./StrategyEditors/TotalSalesForecast";


const SaveButton = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const isEditState = useSelector(state => state.editMenu.isEditState);
    const {saveEditData} = useMutateSalesPlanEntity(props.salesEntityId);

    useImperativeHandle(ref, () => ({
        saveEditData: onClick,
        isEditState: () => isEditState,
    }));

    const onClick = () => {
        Object.keys(props.formRefs.current).forEach(k => {
            if (props.formRefs.current[k]) {
                props.formRefs.current[k].saveEditData();
            }
        });

        saveEditData();
        dispatch(resetEditState());
    };

    return (
        <Button
            icon={'save'}
            disabled={!isEditState}
            onClick={onClick}
            activeStateEnabled={false}
        />
    );
});


const RevertButton = props => {
    const dispatch = useDispatch();
    const isEditState = useSelector(state => state.editMenu.isEditState);

    const onClick = () => {
        Object.keys(props.formRefs.current).forEach(k => props.formRefs.current[k].cancelEdit());
        dispatch(resetEditState());
    };

    return (
        <Button
            icon={'revert'}
            disabled={!isEditState}
            onClick={onClick}
            activeStateEnabled={false}
        />
    );
};


const EditStrategies = forwardRef((props, ref) => {
    const formRefs = useRef({});
    const dispatch = useDispatch();

    const {data: salesEntityData, isSuccess} = useSalesEntityId(props.salesEntityData.id);

    const baseActiveStrategy = salesEntityData?.active_strategy;
    const activeStrategy = useSelector(state => state.editMenu.activeStrategy) || baseActiveStrategy;

    // Array of strategies to display inside details
    const strategies = [
        {
            value: 'sales_history',
            text: 'Previous sales',
            component: (
                <SalesHistoryForecast
                    ref={e => formRefs.current.sales_history = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'manual',
            text: 'Manual',
            component: (
                <ManualForecast
                    ref={e => formRefs.current.manual = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'inherit',
            text: 'Inherit',
            component: (
                <ComparativeForecast
                    ref={e => formRefs.current.inherit = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'replacement_part',
            text: 'Replacement part',
            component: (
                <ReplacementPartForecast
                    ref={e => formRefs.current.replacement_part = e}
                    dataSource={salesEntityData}
                />
            ),
        },
        {
            value: 'total_sales',
            text: 'Total sales',
            component: (
                <TotalSalesForecast
                    ref={e => formRefs.current.total_sales = e}
                    dataSource={salesEntityData}
                />
            ),
        }
    ];

    // Show load panel if data is not loaded yet
    if (!isSuccess) {
        return (
            <LoadPanel
                position={{
                    of: '#edit-strategy-popup',
                }}
                visible={true}
            />
        )
    }

    return (
        <Box height={'100%'} width={'100%'} direction={'col'}>
            <BoxItem key={'toolbar'} baseSize={50} shrink={0}>
                <Toolbar style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <ToolbarItem
                        location={'before'}
                        render={() => <div className={'toolbar-label'}><b>Use forecast type:</b></div>}
                    />
                    <ToolbarItem
                        widget={'dxSelectBox'}
                        location={'before'}
                        options={{
                            value: activeStrategy,
                            valueExpr: 'value',
                            displayExpr: 'text',
                            onValueChanged: ({value}) => (
                                value === baseActiveStrategy
                                    ? dispatch(resetActiveStrategy())
                                    : dispatch(setActiveStrategy(value))
                            ),
                            items: strategies.map(s => ({value: s.value, text: s.text})),
                        }}
                    />
                    <ToolbarItem location={'before'}>
                        <div className={'header-comment'}>
                            {
                                baseActiveStrategy !== activeStrategy && isSuccess
                                    ? `(was "${strategies.filter(s => s.value === baseActiveStrategy)[0]?.text}")`
                                    : ''
                            }
                        </div>
                    </ToolbarItem>
                    <ToolbarItem
                        location={'after'}
                    >
                        <SaveButton
                            ref={ref}
                            salesEntityId={props.salesEntityData.id}
                            formRefs={formRefs}
                        />
                    </ToolbarItem>
                    <ToolbarItem
                        location={'after'}
                    >
                        <RevertButton formRefs={formRefs}/>
                    </ToolbarItem>
                </Toolbar>
            </BoxItem>
            <BoxItem key={'strategies'} baseSize={500} ratio={1}>
                <MultiView
                    animationEnabled={false}
                    swipeEnabled={false}
                    selectedIndex={strategies.map(s => s.value).indexOf(activeStrategy)}
                    height={'100%'}
                >
                    {strategies.map((s, i) => (<MultiViewItem key={i}>{s.component}</MultiViewItem>))}
                </MultiView>
            </BoxItem>
        </Box>
    );
});

export default EditStrategies;
