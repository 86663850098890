import {useEffect, useState} from "react";
import {Route, Switch, Redirect, HashRouter} from "react-router-dom";
import {Divider} from "@mui/material";
import {useQueryClient} from "react-query";
import {Provider} from "react-redux";

import ForecastOverview from "./components/ForecastOverview.js";
import Login from "./components/App/Login.js";
import Menu from "./features/Menu/Menu.js";

import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {hasAccessToken, removeTokens} from "./api/auth";
import {SalesPlanInfoContext} from "./components/common/SalesPlanInfoContext";
import store from "./store";

import './App.css';


function App() {
    const [isAuth, setAuth] = useState(false);
    const [salesPlanId, setSalesPlanId] = useState();
    const queryClient = useQueryClient();

    const logout = () => {
        setAuth(false);
        removeTokens();
    }

    queryClient.setDefaultOptions({
        queries: {
            onError: e => {
                if ([400, 404].includes(e?.response?.status)) {
                    logout();
                }
            },
        },
        mutations: {
            onError: e => {
                if ([400, 404].includes(e?.response?.status)) {
                    logout();
                }
            },
        }
    });


    useEffect(() => {
        const token = hasAccessToken();
        if (token && !isAuth) {
            setAuth(true);
        } else if (!token && isAuth) {
            setAuth(false);
        }
    });

    if (!isAuth) {
        return <Login onSuccess={() => setAuth(true)}/>
    }

    function handleSalesPlanChanged(e) {
        setSalesPlanId(e.value);
    }

    return (
        <SalesPlanInfoContext.Provider value={{id: salesPlanId}}>
            <Provider store={store}>
                <HashRouter>
                    <Menu
                        onLogout={logout}
                        onSalesPlanChanged={handleSalesPlanChanged}
                    />
                    <Divider/>
                    <Switch>
                        <Route path={'/forecast'}>
                            <ForecastOverview salesPlanId={salesPlanId}/>
                        </Route>
                        <Route path={'/logout'}>
                            <Redirect to={'/'}/>
                        </Route>
                        <Route path={'/'}>
                            <div>Go to the forecasts page (choose "Forecast" in the menu in top-left corner)</div>
                        </Route>
                    </Switch>
                </HashRouter>
            </Provider>
        </SalesPlanInfoContext.Provider>
    )
}

export default App;
