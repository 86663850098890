import {ArgumentAxis, Chart, CommonSeriesSettings, Series, Tooltip, ValueAxis} from "devextreme-react/chart";
import {useSelector} from "react-redux";
import {useCallback, useEffect, useRef, useState} from "react";
import get from "just-safe-get";

import useSalesPlanEntity from "../../api/useSalesPlanEntity";
import {formatForecastMonth} from "../../components/utils";

export function SalesEntityChart(props) {
    const chartRef = useRef();
    const dataSource = [];

    const [seriesVisible, setSeriesVisibility] = useState({
        'Edited plan': true,
        'Current plan': true,
        'Compare plan': true,
    });
    const [seriesPresent, setSeriesPresent] = useState({
        'Edited plan': false,
        'Compare plan': false,
    });

    const {
        salesEntityId,
        compareEntityId,
        activeStrategy,
        editData,
        isEditState,
    } = useSelector(state => state.editMenu);
    const salesEntityData = useCallback(useSalesPlanEntity, [salesEntityId])(salesEntityId).data;
    const {strategies} = salesEntityData;

    const compareEntityData = useCallback(useSalesPlanEntity, [compareEntityId])(compareEntityId).data;

    if (Object.keys(salesEntityData).length > 0) {
        const selectedStrategy = activeStrategy || salesEntityData.active_strategy;
        const editedForecast = (editData[selectedStrategy] || Boolean(activeStrategy)
            ? strategies[selectedStrategy].calculateForecast(editData[selectedStrategy])
            : {});

        salesEntityData.predictions.forEach(p => dataSource.push({
                forecastDate: new Date(p.year, p.month - 1, 1),
                year: p.year,
                month: p.month,
                isFact: formatForecastMonth(p.year, p.month) < salesEntityData.startMonth,
                forecast: p.forecast,
                editForecast: get(editedForecast, formatForecastMonth(p.year, p.month), p.forecast),
                compareForecast: null,
                compareIsFact: null,
            })
        );
    }

    if (Object.keys(compareEntityData).length > 0) {
        dataSource.forEach(entry => {
            const comparePrediction = compareEntityData.predictions.find(
                p => p.year === entry.year && p.month === entry.month
            );
            if (comparePrediction) {
                Object.assign(entry, {
                    compareForecast: comparePrediction.forecast,
                    compareIsFact: entry.forecastDate < comparePrediction.startMonth,
                });
            }
        })
    }

    // Set 'Edited plan' series present status
    useEffect(() => {
        setSeriesPresent(state => ({
            ...state,
            'Edited plan': isEditState,
        }));
    }, [isEditState]);

    // Set 'Compare plan' series preset status
    useEffect(() => {
        setSeriesPresent(state => ({
            ...state,
            'Compare plan': Object.keys(compareEntityData).length > 0,
        }));
    }, [compareEntityData]);

    // Re-render chart when opening the Edit menu to adapt it to the container size
    useEffect(() => {
        if (chartRef.current?.instance) {
            setTimeout(() => chartRef.current.instance.render(), 150);
        }
    }, [salesEntityId]);

    function stylizePoint(pt) {
        const factField = pt.seriesName === 'Compare plan' ? 'compareIsFact' : 'isFact';

        const planPointStyle = {
            color: 'white',
            border: {
                color: pt.series.getColor(),
                width: 2,
            },
            hoverStyle: {
                color: 'white',
                border: {
                    color: pt.series.getColor(),
                },
            }
        };

        return pt.data[factField] ? {} : planPointStyle;
    }

    function makeTooltip(pointInfo) {
        return {
            html: `<div style="text-align: center">
                ${pointInfo.seriesName}<br>
                ${pointInfo.argumentText}: <b>${pointInfo.valueText}</b>
                </div>`,
        };
    }

    return (
        <Chart
            ref={chartRef}
            dataSource={dataSource}
            animation={false}
            height={'100%'}
            width={'100%'}
            customizePoint={stylizePoint}
            onLegendClick={({target}) => {
                if (target.name === 'Compare plan') {
                    setSeriesVisibility(state => ({
                        ...state,
                        [target.name]: !seriesVisible[target.name],
                    }));
                }
            }}
        >
            <Tooltip
                enabled={true}
                zIndex={10000}
                argumentFormat={'y/MM'}
                format={'#0.#'}
                arrowLength={10}
                customizeTooltip={makeTooltip}
            />
            <ArgumentAxis
                argumentType={'datetime'}
                tick={{visible: true}}
                grid={{visible: true}}
                minorTickInterval={'month'}
                minorTick={{visible: true}}
                minorGrid={{visible: true}}
            />
            <ValueAxis
                showZero={true}
                minorTick={{visible: true}}
                minorGrid={{visible: true}}
            />
            <CommonSeriesSettings
                argumentField={'forecastDate'}
                type={'line'}
                point={{
                    size: 5,
                    border: {
                        visible: true,
                    }
                }}
            />
            <Series
                valueField={'editForecast'}
                type={'line'}
                name={'Edited plan'}
                visible={seriesPresent['Edited plan'] && seriesVisible['Edited plan']}
                dashStyle={'dash'}
                color={'green'}
            />
            <Series
                valueField={'forecast'}
                type={'line'}
                name={'Current plan'}
                visible={seriesVisible['Current plan']}
                color={'DeepSkyBlue'}
            />
            <Series
                valueField={'compareForecast'}
                type={'line'}
                name={'Compare plan'}
                visible={seriesPresent['Compare plan'] && seriesVisible['Compare plan']}
                color={'red'}
            />
        </Chart>
    );
}
