import {useProductsByProductGroupId, useSalesPlanIdQuery} from "./useAPI";

export default function useSalesPlan(id) {
    // Get SalesPlan
    const {data} = useSalesPlanIdQuery(id, {
        notifyOnChangeProps: ['data'],
        enabled: Boolean(id),
    });

    // Get ProductGroup
    const {data: productGroup} = useProductsByProductGroupId(data?.product_group?.id, {
        notifyOnChangeProps: ['data'],
        enabled: Boolean(data?.product_group?.id),
    });

    return {
        data,
        productGroup,
    }
}
