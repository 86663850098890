import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Popup} from "devextreme-react/popup";
import {Box, Item} from "devextreme-react/box";
import {ErrorBoundary} from "@sentry/react";

import EditStrategies from "./EditStrategies";
import Fallback from "../../components/Fallback";
import {SavePopup} from "../../components/common/SavePopup";
import {getIsIPMappingChanged, setDefaults} from "./InventoryPlannerMapping/InventoryPlannerMappingSlice";

import './editMenu.css';
import InfoPanel from "./InfoPanel";


function EditMenu(props) {
    const popupRef = useRef();
    const editRef = useRef();
    const data = props.editRow?.data;
    const key = props.editRow?.key;

    const dispatch = useDispatch();
    const isIPMappingChanged = useSelector(getIsIPMappingChanged);

    const [isDialogVisible, setDialogVisibility] = useState(false);

    useEffect(() => {
        dispatch(setDefaults(data));
    }, [data]);

    function onSave() {
        editRef.current?.saveEditData();
        props.onHiding();
    }

    function onDiscard(e) {
        if (editRef.current?.isEditState() || isIPMappingChanged) {
            setDialogVisibility(true);
            e.cancel = true;
        } else {
            props.onHiding();
        }
    }

    function setContentPadding(e) {
        const contentElement = e.component.content();
        contentElement.style.paddingTop = '0px';
    }

    return (
        <div>
            <Popup
                ref={popupRef}
                key={'edit_popup'}
                title={'Edit product forecast'}
                showTitle={true}
                width={'95%'}
                height={'95%'}
                shading={true}
                dragEnabled={false}
                resizeEnabled={false}
                closeOnOutsideClick={false}
                visible={props.visible}
                onHiding={onDiscard}
                onHidden={props.onHidden}
                onContentReady={setContentPadding}
                toolbarItems={[
                    {
                        toolbar: 'bottom',
                        location: 'center',
                        widget: 'dxButton',
                        options: {
                            text: 'Save & close',
                            stylingMode: 'contained',
                            onClick: onSave,
                        },
                    },
                    {
                        toolbar: 'bottom',
                        location: 'center',
                        widget: 'dxButton',
                        options: {
                            text: 'Close',
                            stylingMode: 'outlined',
                            onClick: onDiscard,
                        },
                    },
                ]}
            >
                <Box direction={'col'} height={'100%'}>
                    <Item baseSize={225} ratio={0} shrink={1}>
                        <InfoPanel
                            dataSource={data}
                        />
                    </Item>
                    <Item baseSize={300} ratio={1}>
                        <ErrorBoundary fallback={Fallback}>
                            <div id={'edit-strategy-popup'} style={{height: '100%'}}>
                                {key !== null ?
                                    <EditStrategies
                                        ref={editRef}
                                        salesEntityData={data}
                                    /> : ''}
                            </div>
                        </ErrorBoundary>
                    </Item>
                </Box>
                <SavePopup
                    visible={isDialogVisible}
                    onHiding={(e) => {
                        setDialogVisibility(false);
                    }}
                    onSaveClick={() => {
                        editRef.current?.saveEditData();
                        setDialogVisibility(false);
                        props.onHiding();
                    }}
                    onDiscardClick={() => {
                        setDialogVisibility(false);
                        props.onHiding();
                    }}
                    onCancelClick={() => {
                        setDialogVisibility(false);
                    }}
                />
            </Popup>
        </div>
    )
}

export default EditMenu;