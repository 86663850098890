import {createSlice} from "@reduxjs/toolkit";


export const salesPlanGridSlice = createSlice({
    name: 'salesPlanGrid',
    initialState: {
        comparePlanId: null,
        selectedRowKeys: [],
        monthRange: ['2021/09', '2022/12'],
        showColumns: [
            'product_image_url',
            'marketplace',
            'region',
            'product_title',
            'active_strategy',
        ],
    },
    reducers: {
        selectCompareSalesPlan: (state, action) => {
            state.comparePlanId = action.payload;
        },
        deselectCompareSalesPlan: (state) => {
            state.comparePlanId = null;
        },
        setShowColumns: (state, action) => {
            state.showColumns = action.payload;
        },
        setMonthRange: (state, action) => {
            state.monthRange = action.payload;
        },
        setSelectedRowKeys: (state, action) => {
            state.selectedRowKeys = action.payload;
        },
    },
});

export const {
    selectCompareSalesPlan,
    deselectCompareSalesPlan,
    setShowColumns,
    setMonthRange,
    setSelectedRowKeys,
} = salesPlanGridSlice.actions;
export default salesPlanGridSlice.reducer;
