import {createSlice} from "@reduxjs/toolkit";
import clone from "just-clone";

const initialState = {
    salesEntityId: null,
    compareEntityId: null,
    isEditState: false,
    editData: {},
    activeStrategy: null,
};

export const editMenuSlice = createSlice({
    name: 'editMenu',
    initialState: initialState,
    reducers: {
        setSalesEntityId: (state, action) => {
            state.isEditState = false;
            state.salesEntityId = action.payload;
        },
        removeSalesEntityId: () => {
            return initialState;
        },
        resetEditState: (state) => {
            state.isEditState = false;
            state.editData = {};
            state.activeStrategy = null;
        },
        setActiveStrategy: (state, action) => {
            state.isEditState = true;
            state.activeStrategy = action.payload;
        },
        resetActiveStrategy: (state) => {
            state.activeStrategy = null;
            if (Object.keys(state.editData).length === 0) {
                state.isEditState = false;
            }
        },
        updateEditState: (state, action) => {
            const {strategyType, editData} = action.payload;
            state.isEditState = true;
            state.editData[strategyType] = clone(editData);
        },
        setCompareEntityId: (state, action) => {
            state.compareEntityId = action.payload;
        },
    },
});

export const {
    setActiveStrategy,
    resetActiveStrategy,
    setSalesEntityId,
    removeSalesEntityId,
    resetEditState,
    updateEditState,
    setCompareEntityId,
} = editMenuSlice.actions;
export default editMenuSlice.reducer;
