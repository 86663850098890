import {createSlice} from "@reduxjs/toolkit";

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        salesPlanId: null,
    },
    reducers: {
        selectSalesPlan: (state, action) => {
            state.salesPlanId = action.payload;
        },
    },
});

export const {selectSalesPlan} = menuSlice.actions;
export default menuSlice.reducer;
