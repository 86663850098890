import {forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DataGrid, Editing} from "devextreme-react/data-grid";

import {removeEditButtonsFromToolbar} from "../../../components/utils";
import {updateEditState} from "../editMenuSlice";
import useSalesPlanEntity from "../../../api/useSalesPlanEntity";


const STRATEGY = 'manual';

function getColumns(months, fromYearMonth) {
    return [{
        dataField: 'metric',
        caption: '',
        width: 220,
        fixed: true,
        allowEditing: false,
        allowHeaderFiltering: true,
        allowSorting: true,
        cssClass: 'metric',
    }, ...months?.map(month => ({
        dataField: month,
        dataType: 'number',
        format: ',##0',
        editorOptions: {
            format: ',##0',
            step: 100,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
        visible: month >= fromYearMonth,
    }))];
}


export const ManualForecast = forwardRef((props, ref) => {
    const gridRef = useRef();
    const dispatch = useDispatch();

    const salesEntityId = useSelector(state => state.editMenu.salesEntityId);

    // Get SalesEntityData
    const salesEntity = useCallback(useSalesPlanEntity, [salesEntityId])(salesEntityId).data;
    const {months, startMonth, strategies: {[STRATEGY]: strategy}} = salesEntity;

    const columns = useMemo(() => getColumns(months, startMonth), [months, startMonth]);

    useImperativeHandle(ref, () => ({
        cancelEdit: () => gridRef.current.instance?.cancelEditData(),
        saveEditData: () => gridRef.current.instance?.saveEditData(),
    }));

    const handleOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateEditState({
                strategyType: STRATEGY,
                editData: e.value,
            }))
        }
    };

    return (
        <DataGrid
            ref={e => {
                gridRef.current = e;
                ref(e);
            }}
            keyExpr={'metric'}
            align={'center'}
            height={'100%'}
            showBorders={true}
            loadPanel={{enabled: false}}
            dataSource={strategy.data}
            columns={columns}
            onToolbarPreparing={removeEditButtonsFromToolbar}
            onOptionChanged={handleOptionChanged}
        >
            <Editing
                allowUpdating={true}
                mode={'batch'}
            />
        </DataGrid>
    )
});
