import get from "just-safe-get";
import clone from "just-clone";

import {formatForecastMonth} from "../components/utils";

const strategyName = 'manual';
const planField = 'Forecast';

export default class ManualModel {
    constructor(salesPlanEntity, salesPlan) {
        this.data = [];
        this.salesPlanEntity = salesPlanEntity;
        this.startMonth = formatForecastMonth(salesPlan.from_year, salesPlan.from_month);

        const extractStrategyParamField = field => Object.fromEntries(
            salesPlanEntity.predictions.map(p => ([
                formatForecastMonth(p.year, p.month),
                get(p, ['strategy_parameters', strategyName, field], null)
            ]))
        );

        if (salesPlanEntity?.predictions) {
            const columns = extractStrategyParamField(planField);

            this.data.push({
                metric: planField,
                ...columns,
            });
        }
    }

    applyEditData(editData = null) {
        const newData = clone(this.data);

        if (editData) {
            editData.forEach(row => {
                const newDataRow = newData.find(newDataRow => newDataRow.metric === row.key);
                if (newDataRow) {
                    Object.assign(newDataRow, row.data);
                } else {
                    console.warn(`Unknown key (${row.key}) in the editData`);
                }
            });
        }

        return newData;
    }

    forecastForMonth(month, data) {
        if (month < this.startMonth) {
            return this.salesPlanEntity.predictions.find(p => formatForecastMonth(p.year, p.month) === month).forecast;
        }

        return data[0][month];
    }

    calculateForecast(editData = null) {
        const forecast = this.data[0];
        const editedData = this.applyEditData(editData);

        return Object.fromEntries(Object.keys(forecast).filter(k => k !== 'metric').map(
            month => [month, this.forecastForMonth(month, editedData)]
        ));
    }

    toSalesEntityFormat(editData = null) {
        const data = editData ? this.applyEditData(editData) : this.data;
        const forecast = this.calculateForecast(editData);

        const predictions = this.salesPlanEntity.predictions.map(p => {
            const month = formatForecastMonth(p.year, p.month);
            return {
                id: p.id,
                year: p.year,
                month: p.month,
                forecast: forecast[month] == null ? p.forecast : forecast[month],
                strategy_parameters: {
                    [strategyName]: {
                        [planField]: data.find(row => row.metric === planField)?.[month],
                    },
                },
            };
        });

        return {
            predictions,
            strategy_parameters: {},
        };
    }
}
