import {forwardRef, useCallback, useImperativeHandle, useRef} from "react";
import {Template} from "devextreme-react";
import {Button} from "devextreme-react/button";
import {DataGrid, Editing} from "devextreme-react/data-grid";
import {useDispatch, useSelector} from "react-redux";

import {
    onlyUnique,
    ProductsManager,
    removeEditButtonsFromToolbar
} from "../../../components/utils";
import {updateEditState} from "../editMenuSlice";
import useSalesPlanEntity from "../../../api/useSalesPlanEntity";
import useSalesPlan from "../../../api/useSalesPlan";


const STRATEGY = 'inherit';

function getColumns(months, startYearMonth, salesPlanData, productGroupData) {
    const productsManager = new ProductsManager(productGroupData?.products);
    const productString = (i) => productsManager.getProductById(i)?.asins?.map(s => s.title)?.join(' / ');

    function getLookupFilter(fieldName) {
        // Columns used to find acceptable parent forecasts
        const fieldsToCheck = ['marketplace', 'region', 'parent_product_id'].filter(i => i !== fieldName);

        return ({data}) => {
            // When there is no datasource -- return empty lookup
            if (data === undefined
                || salesPlanData?.sales_plan_entities === undefined
                || productGroupData?.products === undefined) {
                return [];
            }

            // Check for the acceptable entries in the datasource
            const res = salesPlanData?.sales_plan_entities.map(entity => ({
                ...entity,
                parent_product_id: entity.product.id,
            })).filter(entity => fieldsToCheck.every(field => (
                data[field] === entity[field] || data[field] === null || data[field] === undefined
            )))
                .map(row => row[fieldName])
                .filter(onlyUnique);

            // If current column if 'parent_product_id' we need to separate cell value and display value
            if (fieldName === 'parent_product_id') {
                return res.map(i => ({
                    name: productString(i),
                    value: i,
                }));
            } else {
                return res;
            }
        };
    }

    return [{
        headerCellTemplate: 'renderAddRowButton',
        type: 'buttons',
        fixed: true,
        fixedPosition: 'left',
        width: 50,
        caption: 'Edit',
    }, {
        dataField: 'marketplace',
        fixed: true,
        width: 120,
        lookup: {
            allowClearing: true,
            dataSource: getLookupFilter('marketplace'),
        },
        validationRules: [{type: 'required'}],
    }, {
        dataField: 'region',
        fixed: true,
        width: 80,
        lookup: {
            allowClearing: true,
            dataSource: getLookupFilter('region'),
        },
        validationRules: [{type: 'required'}],
    }, {
        dataField: 'parent_product_id',
        caption: 'Product',
        calculateDisplayValue: row => {
            return productString(row.parent_product_id);
        },
        fixed: true,
        width: 250,
        lookup: {
            allowClearing: true,
            dataSource: getLookupFilter('parent_product_id'),
            displayExpr: 'name',
            valueExpr: 'value',
        },
        validationRules: [{type: 'required'}],
    }, ...months?.map(month => ({
        dataField: month,
        dataType: 'number',
        format: '#0.#%',
        editorOptions: {
            format: '#0.#%',
            step: 0.1,
        },
        width: 80,
        cssClass: 'metric-values',
        allowSorting: false,
        visible: month >= startYearMonth,
    }))];
}

export const ComparativeForecast = forwardRef((props, ref) => {
    const gridRef = useRef();
    const dispatch = useDispatch();

    const salesPlanId = useSelector(state => state.menu.salesPlanId);
    const salesEntityId = useSelector(state => state.editMenu.salesEntityId);

    // Get SalesEntity and strategy data
    const salesEntity = useCallback(useSalesPlanEntity, [salesEntityId])(salesEntityId).data;
    const {months, startMonth, strategies: {[STRATEGY]: strategy}} = salesEntity;

    // Get SalesPlan and ProductGroup
    const {data: salesPlan, productGroup} = useCallback(useSalesPlan, [salesPlanId])(salesPlanId);

    const columns = getColumns(months, startMonth, salesPlan, productGroup);

    useImperativeHandle(ref, () => ({
        cancelEdit: () => gridRef.current.instance?.cancelEditData(),
        saveEditData: () => gridRef.current.instance?.saveEditData(),
    }));

    function handleInitNewRow({data: newRow}) {
        newRow.parent_product_id = props.dataSource?.product?.id;
    }

    const handleOptionChanged = e => {
        if (e.fullName === 'editing.changes') {
            dispatch(updateEditState({
                strategyType: STRATEGY,
                editData: e.value,
            }));
        }
    };

    return (
        <DataGrid
            ref={e => {
                gridRef.current = e;
                ref(e);
            }}
            height={'100%'}
            dataSource={strategy.data}
            columns={columns}
            onToolbarPreparing={removeEditButtonsFromToolbar}
            onInitNewRow={handleInitNewRow}
            onOptionChanged={handleOptionChanged}
        >
            <Editing
                mode={'batch'}
                allowAdding={true}
                allowDeleting={true}
                allowUpdating={true}
                useIcons={true}
            />
            <Template
                name={'renderAddRowButton'}
            >
                <Button icon={'add'} onClick={() => gridRef.current.instance?.addRow()}/>
            </Template>
        </DataGrid>
    )
});
