import './ItemInfo.css';


function InfoField({name, value, ...props}) {
    return (
        <div className={'dx-field-margin dx-field'}>
            <div className={'dx-field-label dx-field-label-align'}>{name}</div>
            <div
                className={'dx-field-value-static'}
                style={{padding: '0px 8px', width: '100%'}}
            >
                {value}
            </div>
        </div>
    );
}


export function SalesEntityInfo(props) {
    const skusRepr = props?.product?.skus?.map ? props.product.skus.map(i => i.title).join(', ') : '-';
    const asinsRepr = props?.product?.asins?.map ? props.product.asins.map(i => i.title).join(', ') : '-';

    return (
        <div className={'dx-fieldset'}>
            <InfoField name={'Marketplace'} value={props.marketplace}/>
            <InfoField name={'Region'} value={props.region}/>
            <InfoField name={'Product title'} value={props.product?.title}/>
            <InfoField name={'Product ID'} value={props.product?.description}/>
            <InfoField name={'SKUs'} value={skusRepr}/>
            <InfoField name={'ASINs'} value={asinsRepr}/>
        </div>
    );
}
